import React from "react"
import { NotFoundContainer } from "components/Errors/NotFound"


const NotFoundPage = () => {
    
    return (
            <NotFoundContainer/>
    )
}

export default NotFoundPage
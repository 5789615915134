import React from "react"
import { ServerErrorContainer } from "components/Errors/ServerError"


const ServerErrorPage = () => {
    return (
        <ServerErrorContainer/>
    )
}

export default ServerErrorPage